import React, { useEffect } from 'react'
import { FirstStep, SecondStep, ThirdStep, FourthStep } from './partials/Steps/IndexStep';
import './App.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContextApp from './context/ContextApp'

const App = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [dataStep1, setDataStep1] = React.useState(undefined)
  const [dataStep2, setDataStep2] = React.useState(undefined)
  const [dataStep3, setDataStep3] = React.useState(undefined)
  const [dataFinal, setDataFinal] = React.useState(undefined)

  const onChangeFinalStep = () => {

    const { id } = dataStep1
    const { peso, idade, altura, sexo } = dataStep2
    const { multiple } = dataStep3

    let gastoCaloricoTotal = 0
    let proteinas = peso * 2

    switch (id) {
      case 0:
        if (sexo === "Masculino")
          gastoCaloricoTotal = (66.5 + (13.75 * peso) + (5.003 * altura) - (6.775 * idade)) * multiple
        else
          gastoCaloricoTotal = (655.1 + (9.563 * peso) + (1.850 * altura) - (4.676 * idade)) * multiple
        break;

      case 1:
        if (sexo === "Masculino")
          gastoCaloricoTotal = (66.5 + (13.75 * peso) + (5.003 * altura) - (6.775 * idade)) * multiple
        else
          gastoCaloricoTotal = (655.1 + (9.563 * peso) + (1.850 * altura) - (4.676 * idade)) * multiple
        break;

      case 2:
        if (sexo === "Masculino")
          gastoCaloricoTotal = ((10 * peso) + (6.25 * altura) - (5 * idade) + 5) * multiple
        else
          gastoCaloricoTotal = ((10 * peso) + (6.25 * altura) - (5 * idade) - 161) * multiple
        break;

      case 3:
        gastoCaloricoTotal = { multiple }
        break;

      default:
        break;

    }

    setDataFinal({ gastoCaloricoTotal, proteinas })

  }

  useEffect(() => {
    if (!!dataFinal) setActiveStep(3)
  }, [dataFinal])

  return (
    <ContextApp.Provider value={{ dataStep1, setDataStep1, dataStep2, setDataStep2, dataStep3, setDataStep3 }}>
      <header>
        <div className='p-3 d-flex jutify-content-center'>
          <img src={require('./assets/icons/logo.png')} style={{ width: 300 }} className="mx-auto d-block" alt="img-icon" />
        </div>
      </header>

      <div className="d-flex justify-content-center align-items-center col-10 offset-1">
        {activeStep === 0 && <FirstStep onNextStep={() => setActiveStep(1)} />}
        {activeStep === 1 && <SecondStep onNextStep={() => setActiveStep(2)} onPrevStep={() => setActiveStep(0)} />}
        {activeStep === 2 && <ThirdStep onNextStep={() => onChangeFinalStep()} onPrevStep={() => setActiveStep(1)} />}
        {activeStep === 3 && <FourthStep onResetForm={() => setActiveStep(0)} dataFinal={dataFinal} />}
      </div>

      <div className='d-flex justify-content-center mt-5 p-3'>
        <a href='https://www.instagram.com/annymoura.nutricao/' target="noopener" className='m-3'>
          <img src={require('./assets/icons/ig-icon.png')} style={{ width: 30 }} alt="img-icon" />
        </a>

        <a href='https://api.whatsapp.com/send?phone=556192671392' target="noopener" className='m-3'>
          <img src={require('./assets/icons/icon-wpp.png')} style={{ width: 30 }} alt="img-icon" />
        </a>

      </div>
    </ContextApp.Provider>
  );
}


export default App;
